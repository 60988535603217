import dashboard from './dashboard'
import clients from './clients'
import prayers from './prayers'
// import prayersAndBookings from './prayers-and-bookings'
// import financial from './financial'
// import primaryUsersAndFollowers from './primary-users-and-followers'
// import announcements from './announcements'
// import others from './others'

// Array of sections
export default [...dashboard, ...clients, ...prayers]
