export default [
  {
    header: 'Other',
  },
  // {
  //   title: 'Manage Prayers',
  //   route: 'manage-prayers',
  //   icon: 'MoonIcon',
  // },
  {
    title: 'Organization Dashboard',
    route: 'organization-dash',
    icon: 'UserIcon',
  },
]
