export default [
  {
    header: 'Clients',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
  },
  {
    title: 'INPOs',
    route: 'organizations',
    icon: 'GridIcon',
  },
  {
    title: 'Commercial',
    route: 'business',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Announcements',
    route: 'announcements',
    icon: 'MicIcon',
  },
  {
    title: 'Other',
    icon: 'CreditCardIcon',
    children: [
      {
        title: 'Tags',
        route: 'tags',
      },
      {
        title: 'Languages',
        route: 'languages',
      },
      {
        title: 'Classifications',
        route: 'classifications',
      },
      {
        title: 'Sub Classifications',
        route: 'sub-classifications',
      },
      {
        title: 'Donation Categories',
        route: 'donation-categories',
      },
    ],
  },
  {
    title: 'Prayer Booking',
    route: 'prayer-booking',
    icon: 'MoonIcon',
  },
  {
    title: 'Live Management',
    route: 'live-management',
    icon: 'VideoIcon',
  },
]
